var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function Button(theme) {
    var isLight = theme.palette.mode === 'light';
    var rootStyle = function (ownerState) {
        var inheritColor = ownerState.color === 'inherit';
        var containedVariant = ownerState.variant === 'contained';
        var outlinedVariant = ownerState.variant === 'outlined';
        var textVariant = ownerState.variant === 'text';
        var softVariant = ownerState.variant === 'soft';
        var smallSize = ownerState.size === 'small';
        var largeSize = ownerState.size === 'large';
        var defaultStyle = __assign({}, (inheritColor && __assign(__assign(__assign(__assign({}, (containedVariant && {
            color: theme.palette.grey[800],
            '&:hover': {
                boxShadow: theme.customShadows.z8,
                backgroundColor: theme.palette.grey[400],
            },
        })), (outlinedVariant && {
            borderColor: alpha(theme.palette.grey[500], 0.32),
            '&:hover': {
                borderColor: theme.palette.text.primary,
                backgroundColor: theme.palette.action.hover,
            },
        })), (textVariant && {
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        })), (softVariant && {
            color: theme.palette.text.primary,
            backgroundColor: alpha(theme.palette.grey[500], 0.08),
            '&:hover': {
                backgroundColor: alpha(theme.palette.grey[500], 0.24),
            },
        }))));
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.color === color && __assign(__assign({}, (containedVariant && {
            '&:hover': {
                boxShadow: theme.customShadows[color],
            },
        })), (softVariant && {
            color: theme.palette[color][isLight ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[color].main, 0.16),
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.32),
            },
        }))))); });
        var disabledState = {
            '&.Mui-disabled': __assign({}, (softVariant && {
                backgroundColor: theme.palette.action.disabledBackground,
            })),
        };
        var size = __assign(__assign({}, (smallSize && __assign({ height: 30, fontSize: 13 }, (softVariant && {
            padding: '4px 10px',
        })))), (largeSize && __assign({ height: 48, fontSize: 15 }, (softVariant && {
            padding: '8px 22px',
        }))));
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle, disabledState, size], false);
    };
    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
